<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <div class="row">
      <div class="col-12">
        <animal-detail :show-detail="true" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table table-hover table-striped">
          <thead>
            <th>Nom de la maladie</th>
            <th>Echéances de vaccination</th>
            <th>Prochaine date de vaccination</th>
            <th>Etat</th>
          </thead>
          <tbody>
            <etat-vaccinal
              v-for="(v, i) in filtredVaccins"
              :key="i"
              :vaccin="v"
            />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import navbar from '../../../components/navbar.vue'
import AnimalDetail from '../../../components/identification/animal/detailInform.vue'
import EtatVaccinal from '../../../components/sanitaire/vaccination/etatVaccinal.vue'
export default {
    components: {navbar, AnimalDetail, EtatVaccinal},
    watch: {
        selectedAnimal(){
            if(this.selectedAnimal !== null){
                let animal = this.animaux.find(item=> item.uid === this.selectedAnimal)
                this.filtredVaccins = [...this.vaccins].filter(item => item.espece === animal.espece)
            }
        }
    },
    data(){
        return {
          navbarItems: [
              {
                  libelle: 'Sanitaire'
              },
              {
                libelle: 'Vaccination',
                route: 'vaccination'
              },
              {libelle: 'Etat vaccinal'}
            ],
            pageIcon: 'la-id-card',
            pageTitle: 'Etat Vaccinal',
            pageDescription: 'Etat vaccinal des animaux',
            filtredVaccins: []
        }
    },
   
    methods: {

    },
    computed: {
        ...mapGetters({
            animaux: 'identification/animaux',
            vaccins: 'sanitaire/vaccins',
            selectedAnimal: 'identification/selectedAnimal'
        }),
        
    }
}
</script>

<style>

</style>